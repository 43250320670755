@use "styles" as *;

.brandTablist {
  grid-column: span 12;
  display: flex;
  gap: 4px;
  white-space: nowrap;
  overflow: auto;
  margin: 0 -16px;
  padding: 1px 16px 8px;
  @include hide-scrollbar;
  @include sm-desktop {
    gap: 8px;
    margin-bottom: 24px;
    margin-inline: -24px;
    padding-right: 24px;
    padding-left: 24px;
  }
}

.brandTab {
  @include text-xs-regular-uppercase;
  padding: 8px 16px;
  border-radius: 8px;
  position: relative;
  &.clickedTab {
    outline: none;
  }
  > span {
    position: relative;
    z-index: 1;
  }
  &:first-of-type {
    margin-inline-start: auto;
  }
  &:last-of-type {
    margin-inline-end: auto;
  }
}

.selectedBox {
  @include color-gradient-light;
  @include tab-gradient;
  position: absolute;
  border-radius: 8px;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
}

.grid {
  row-gap: 20px;
  align-items: center;
}

.media {
  aspect-ratio: 16/9;
  overflow: hidden;
  position: relative;
  border-radius: 16px;
  margin-bottom: 28px;
  > * {
    object-fit: cover;
  }
  @include sm-desktop {
    border-radius: 20px;
    grid-column: span 7;
    margin: 0 32px;
  }
}

.cardCol {
  position: relative;
  grid-column: span 5;
  margin: 0 24px;
  @include sm-desktop {
    &::before,
    &::after {
      content: "";
      position: absolute;
      top: 0;
      width: 24px;
      height: 41px;
      z-index: 2;
      pointer-events: none;
    }
    &::before {
      left: 0;
      transform: translateX(-100%);
      background: linear-gradient(to right, var(--bg-color), transparent);
    }
    &::after {
      right: 0;
      transform: translateX(100%);
      background: linear-gradient(to left, var(--bg-color), transparent);
    }
  }
}

.textContent {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  @include sm-desktop {
    gap: 24px;
  }
}

.title {
  @include sm-desktop {
    margin-bottom: 8px;
    height: 88px;
    display: flex;
    align-items: center;
  }
  @include md-desktop {
    height: 105px;
  }
}

.innerTitle {
  @include text-medium-title;
  @include sm-desktop {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    padding-bottom: 8px;
  }
}

.description {
  @include sm-desktop {
    height: 88px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
  }
}

.brandTabPanels {
  grid-column: span 12;
}

.mobileOnly {
  @include sm-desktop {
    display: none;
  }
}

.desktopOnly {
  @include mobile-only {
    display: none;
  }
}
